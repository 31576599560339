import {reactive} from "vue";

export default reactive({
  folder: {
    name: "Folder",
    icon: "mdi mdi-folder",
    key: "fileExplorer.folders",
    exe: "",
    id: "folder",
    folder: true,
    content: "",
  },
  file: {
    name: "File",
    key: "fileExplorer.files",
    icon: "mdi mdi-file",
    exe: "",
    id: "file",
    content: "",
  },
});
