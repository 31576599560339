import {reactive, ref} from "vue";
import axios from "axios";
import {io} from "socket.io-client";
import EventBus from "@/AppEventBus";
import roles from "@/router/roles";
import i18n from "@/i18n";
import router from "@/router";

export default reactive({
  auth: {
    init: false,
    isAuth: false,
  },
  token: "",
  plan: undefined,
  isApp: false,
  user: undefined,
  happy: {},
  role: undefined,
  adminRoute: {
    Home: ["/app/dashboard", "/app/tasks"],
    Explorer: ["/app/fileExplorer", "/app/shortcut"],
    Users: ["/app/users", "/app/roles", "/app/session"],
    System: ["/app/store", "/app/profile", "/app/setting"],
    About: ["/app/contact", "/app/landing", "/app/website"],
  },
  socket: undefined,
  logo: {
    src: "/images/logo.svg",
    name: "Motanamy EA",
  },
  setting: {
    scale: 14,
    style: "lara-light-blue",
    dark: false,
    layoutMode: "static",
    lang: "en",
  },
  loading: ref(false),
  url:
    process.env.NODE_ENV == "development"
      ? "http://127.0.0.1:3000/api/motanamy/"
      : "https://app.motanamy.com/api/motanamy/",
  loadSetting() {
    this.setting = {
      ...this.setting,
      ...JSON.parse(localStorage.getItem("MotanamyEASetting")),
    };
    this.applyTheme(this.setting);
    this.token = localStorage.getItem("MotanamyEA") || "";
  },
  async validToken() {
    try {
      if (this.token == "") throw new Error();
      const {data} = await axios.get(`auth/validToken`);
      this.signIn(data);
      return true;
    } catch (err) {
      return false;
    }
  },
  applyTheme(theme) {
    document.documentElement.style.fontSize = theme.scale + "px";
    EventBus.emit("theme-change", {theme: theme.style, dark: theme.dark});
    i18n.global.locale = theme.lang || "en";
  },
  roleF(key, name) {
    if (this.user.roleId == "admin") return true;
    else
      return this.role.apps[key]
        ? this.role.apps[key].roles[name].value
        : false;
  },
  logout() {
    this.auth.isAuth = false;
    router.push("/signin").then(res => {
      this.socket.disconnect();
      localStorage.removeItem("MotanamyEA");
      this.user = undefined;
      this.role = undefined;
      this.plan = undefined;
      this.token = undefined;
    });
  },
  signIn(data) {
    this.user = data.user;
    this.role = data.role;
    this.token = data.token;
    this.auth.isAuth = true;
    this.plan = data.plan;
    if (data.token) localStorage.setItem("MotanamyEA", data.token);
    if (data.user.roleId == "admin") {
      this.role.route = this.adminRoute;
      this.role.apps = roles;
    }
    this.socket = io(
      `${
        process.env.NODE_ENV == "development" ? "http://127.0.0.1:3200" : ""
      }/main`,
      {
        extraHeaders: {
          Authorization: `Bearer ${this.token}`,
        },
        query: {
          ...data.user,
        },
      }
    );
    this.socket.on("connect", () => {
      console.log("[socket] connect");
      this.socket.on("[session] closeSession", () => {
        this.logout();
      });
      this.socket.on("[core-users]", data => {
        if (data.o == "update" && data.id == this.user.id) {
          this.logout();
        }
      });
      this.socket.on("[core-roles]", data => {
        if (data.o == "update" && data.id == this.role.id) {
          location.reload();
        }
      });
    });
    this.socket.on("disconnect", reason => {
      console.log("[socket] disconnect");
    });
  },
});
