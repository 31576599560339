import axios from 'axios';
import app from '@/store/app';
import geteRelativeUrl from "@/getUrl"

axios.defaults.baseURL = geteRelativeUrl();
axios.interceptors.request.use(function (config) {
    config.headers.set('Authorization', `Bearer ${app.token}`);
    app.loading = true;
    return config;
}, function (error) {
    return Promise.reject(error);
});
axios.interceptors.response.use(function (response) {
    app.loading = false;
    return response;
}, function (error) {
    app.loading = false;
    return Promise.reject(error);
});
