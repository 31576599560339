import EventBus from "@/AppEventBus";
import {reactive} from "vue";

export default reactive({
  Home: {
    label: "Home",
    key: "menu.home",
    items: {
      "/app/dashboard": {
        key: "menu.dashboard",
        label: "Dashboard",
        icon: "pi pi-fw pi-home",
        to: "/app/dashboard",
      },
      "/app/tasks": {
        label: "Tasks",
        key: "menu.tasks",
        icon: "mdi mdi-file-tree",
        command: () => {
          EventBus.emit("Tasks-change");
        },
      },
    },
  },
  Explorer: {
    label: "Explorer",
    key: "menu.fileExplorer",
    items: {
      "/app/fileExplorer": {
        label: "File Explorer",
        key: "menu.fileExplorer",
        icon: "mdi mdi-folder-table-outline",
        to: "/app/fileExplorer",
      },
      "/app/shortcut": {
        label: "Shortcut",
        key: "menu.shortcut",
        icon: "mdi mdi-folder-star-outline",
        to: "/app/shortcut",
      },
    },
  },
  Users: {
    label: "Users",
    key: "menu.persons",
    items: {
      "/app/users": {
        label: "Users",
        key: "menu.users",
        icon: "mdi mdi-account-multiple-outline",
        items: [
          {
            label: "List",
            key: "menu.list",
            icon: "mdi mdi-format-list-group",
            to: "/app/users",
          },
          {
            label: "Add",
            key: "menu.add",
            icon: "mdi mdi-format-list-group-plus",
            to: "/app/users/add",
          },
        ],
      },
      "/app/roles": {
        label: "Roles",
        key: "menu.roles",
        icon: "mdi mdi-account-key-outline",
        items: [
          {
            label: "List",
            key: "menu.list",
            icon: "mdi mdi-format-list-group",
            to: "/app/roles",
          },
          {
            label: "Add",
            key: "menu.add",
            icon: "mdi mdi-format-list-group-plus",
            to: "/app/roles/add",
          },
        ],
      },
      "/app/session": {
        label: "Sessions",
        key: "menu.session",
        icon: "mdi mdi-cast-connected",
        to: "/app/session",
      },
    },
  },
  System: {
    label: "System",
    key: "menu.system",
    items: {
      "/app/store": {
        key: "menu.store",
        label: "Store",
        icon: "mdi mdi-store-outline",
        to: "/app/store",
      },
      "/app/profile": {
        label: "Profile",
        key: "menu.profile",
        icon: "pi pi-fw pi-id-card",
        to: "/app/profile",
      },
      "/app/setting": {
        label: "Setting",
        key: "menu.setting",
        icon: "pi pi-fw pi-cog",
        items: [
          {
            label: "General",
            key: "menu.general",
            icon: "mdi mdi-cogs",
            to: "/app/setting",
          },
        ],
      },
    },
  },
  About: {
    label: "About",
    key: "menu.about",
    items: {
      "/app/contact": {
        label: "Contact",
        key: "menu.contact",
        icon: "pi pi-fw pi-phone",
        to: "/app/contact",
      },
      "/app/landing": {
        label: "Info",
        key: "menu.info",
        icon: "pi pi-fw pi-question",
        to: "/app/landing",
      },
      "/app/website": {
        label: "Website",
        key: "menu.website",
        icon: "pi pi-fw pi-globe",
        command: () => {
          window.open("https://motanamy.com");
        },
      },
    },
  },
});
