import { createI18n } from "vue-i18n";
import ar from "./ar";
import en from "./en";

export default createI18n({
    locale: 'en',
    messages: {
        en,
        ar
    }
})