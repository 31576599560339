export default {
    menu: {
        dashboard: "Dashboard",
        store: "Store",
        profile: "Profile",
        setting: "Setting",
        general: "General",
        news: "News",
        app: "Application",
        tasks: "Tasks",
        contact: "Contact US",
        info: "Info",
        website: "Website",
        "logout": "Logout",
        home: "Home",
        system: "System",
        about: "About",
        "fileExplorer": "File Explorer",
        "shortcut": "Shortcut",
        "persons": "persons",
        "users": "Users",
        "list": "List",
        "add": "Add",
        "roles": "Roles",
        "session": "Session"
    },
    text: {
        "addNewUser": "Add New User",
        "editedUser": "Edited User",
        "addNewRole": "Add New Role",
        edited: "Edited",
        "EditedRole": "Edited Role",
        "roleInfo": "Role Information",
        done: "Done",
        error: "Error Occur",
        areSure: "Are you sure you want to delete Item ",
        confirmation: "Confirmation",
        sureLogout: "Are you sure you want to logout ",
        yes: "Yes",
        no: "No"
    },
    signIn: {
        welcome: "Welcome",
        signIn: "Sign in to continue",
        button: "Sign In"
    },
    form: {
        email: "Email",
        password: "Password",
        name: "Name",
        username: "Username",
        "id": "ID",
        state: "State",
        path: "url",
        image: "Image",
        "role": "Role",
        description: "Description",
        address: "Address",
        type: "Type",
        version: "Version",
        phone: "Phone",
        message: "Message"
    },
    button: {
        add: "Add",
        delete: "Delete",
        "create": "Create",
        "edited": "Edited",
        close: 'Close',
        "upload": "Upload",
        open: "Open",
        "view": "View",
        save: "Save",
        refresh: "Refresh",
        check: "Check",
        validateToken: "Validate Token",
        license: "License",
        differentLicense: "Different License",
        update: "Update",
        send: "Send",
        more: "Raed More"
    },
    setting: {
        scale: "Scale",
        menu: "Menu Type",
        language: "Select Language",
        theme: "select Theme"
    },
    store: {
        features: "Features",
        version: "Version",
        faq: "Faq"
    },
    404: {
        lost: "Looks like you are lost",
        available: "Requested resource is not available."
    },
    "fileExplorer": {
        "folders": "Folders",
        "files": "Files",
        "rename": "Rename",
        "download": "Download",
        "shortcut": "Shortcut"
    }
}