<template>
  <div class="h-full" id="containerElement" :dir="$i18n.locale == 'ar' ? 'rtl' : null">
    <Task ref="taskRef" />
    <Toast
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :position="$i18n.locale == 'ar' ? 'top-left' : 'top-right'"
    />
    <router-view></router-view>
    <ConfirmDialog
      style="width: 300px;"
      :draggable="false"
      :dir="$i18n.locale == 'ar' ? 'rtl' : null"
    ></ConfirmDialog>
    <DynamicDialog />
    <div class="preloader top-0" v-if="loading">
      <div class="preloader-content"></div>
    </div>
  </div>
</template>

<script>
import EventBus from "./AppEventBus";
import app from "./store/app";
import Task from "./index/task.vue";

export default {
  themeChangeListener: null,
  components: {
    Task
  },
  watch: {
    $route(val) {
      this.$toast.removeAllGroups();
      if (window.matchMedia("(display-mode: standalone)").matches) {
        this.initPWA(val.fullPath);
      }
    }
  },
  computed: {
    loading() {
      return app.loading;
    }
  },
  methods: {
    initPWA(path = "") {
      app.isApp = true;
      if (path.startsWith("/app") == false) {
        this.$router.push("/app");
      }
    }
  },
  mounted() {
    window
      .matchMedia("(display-mode: standalone)")
      .addEventListener("change", evt => {
        if (evt.matches) {
          this.initPWA(this.$route.fullPath);
        }
      });
    this.$appState.theme = "md-light-indigo";
    this.themeChangeListener = event => {
      const elementId = "theme-link";
      const linkElement = document.getElementById(elementId);
      const cloneLinkElement = linkElement.cloneNode(true);
      const newThemeUrl = linkElement
        .getAttribute("href")
        .replace(this.$appState.theme, event.theme);
      cloneLinkElement.setAttribute("id", elementId + "-clone");
      cloneLinkElement.setAttribute("href", newThemeUrl);
      cloneLinkElement.addEventListener("load", () => {
        linkElement.remove();
        cloneLinkElement.setAttribute("id", elementId);
      });
      linkElement.parentNode.insertBefore(
        cloneLinkElement,
        linkElement.nextSibling
      );
      this.$appState.theme = event.theme;
      this.$appState.darkTheme = event.dark;
    };

    EventBus.on("theme-change", this.themeChangeListener);
  },
  beforeUnmount() {
    EventBus.off("theme-change", this.themeChangeListener);
  }
};
</script>
