import {loadModule as _loadModule, createCJSModule} from "./vue3-sfc-loader";
import app from "@/store/app";
import axios from "axios";
import EventBus from "@/AppEventBus";
import navigation from "@/router/navigation";
import geteRelativeUrl from "@/getUrl";

let moduleCache = {
  vue: require("vue"),
  global: {
    CheckError,
    loadModule,
    EventBus,
    navigation,
  },
  axios,
};

export function loadModule(file, name) {
  if (!moduleCache.app) {
    moduleCache.app = app;
  }
  const options = {
    moduleCache: {},
    async handleModule(type, getContentData, path, options) {
      if (type == ".vue" && !path.startsWith("vue:")) return;
      if (path.startsWith("vue:")) {
        let temp = path.slice(path.indexOf("vue:") + 4).split("/");
        return loadModule(temp, `/[${temp[0]}]${temp[temp.length - 1]}`);
      }
      if (path.startsWith("js:")) {
        return createCJSModule(
          ".mjs",
          (
            await axios.get(
              `plugins/file?path=${path
                .slice(path.indexOf("js:") + 3)
                .split("/")
                .join()}`
            )
          ).data,
          options
        ).exports;
      }
      if (path.startsWith("file:")) {
        let url = path.slice(path.indexOf("file:") + 5);
        if (document.getElementById(url) == null) {
          let script = document.createElement("script");
          script.id = url;
          if (url.startsWith("http")) {
            script.src = url;
          } else {
            url = url.split("/");
            script.src = `${geteRelativeUrl()}plugins/file?path=${url.join()}`;
          }
          document.head.append(script);
        }
        return null;
      }
      if (path.startsWith("cssFile:")) {
        let url = path.slice(path.indexOf("cssFile:") + 8);
        if (document.getElementById(url) == null) {
          let script = document.createElement("link");
          script.rel = "stylesheet"
          script.id = url;
          if (url.startsWith("http")) {
            script.href = url;
          } else {
            url = url.split("/");
            script.href = `${geteRelativeUrl()}plugins/file?path=${url.join()}`;
          }
          document.head.append(script);
        }
        return null;
      }
      if (path.startsWith("css:")) {
        let url = path.slice(path.indexOf("css:") + 4).split("/");
        options.addStyle(
          (await axios.get(`plugins/file?path=${url.join()}`)).data,
          url.join("-")
        );
        return null;
      }
      if (moduleCache[path]) return moduleCache[path];
      return undefined;
    },
    getFile: x => {
      if (x.startsWith("/"))
        return axios
          .get(`plugins/file?path=${file.join()}`)
          .then(res => Promise.resolve(res.data));
      return "";
    },
    addStyle(textContent, key) {
      if (document.head.querySelector(`#${key}`) != null) return;
      let ele = document.createElement("style");
      ele.id = key;
      ele.innerHTML = textContent;
      document.head.append(ele);
    },
  };
  return _loadModule(name, options);
}
export function CheckError(response) {
  if (response.status >= 200 && response.status <= 299) {
    return response.json();
  } else {
    throw Error(response.statusText);
  }
}
