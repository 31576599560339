<template>
  <div
    :class="{ 'hidden': !visible }"
    @click="visible = false;"
    class="h-full overflow-auto task fixed top-0 left-0 right-0 bottom-0 bg-black-alpha-30"
    :modal="true"
  >
    <div class="grid m-4" @click="visible = false;">
      <component
        v-for="(item, index) in data"
        :key="index"
        :is="item"
        @click="$event.stopPropagation();"
      ></component>
    </div>
    <Message
      :closable="false"
      class="m-5"
      severity="warn"
      v-if="data.length == 0"
    >Not found any Task to run</Message>
  </div>
</template>

<script>
import plugins from "@/store/plugins";
import EventBus from "@/AppEventBus";

export default {
  computed: {
    data() {
      return plugins.tasks;
    }
  },
  created() {
    EventBus.on("Tasks-change", () => {
      this.visible = !this.visible;
    });
    document.addEventListener("keydown", e => {
      if (e.ctrlKey && e.key == "z") {
        this.visible = !this.visible;
      }
    });
  },
  data() {
    return {
      visible: false
    };
  }
};
</script>

<style scoped>
.task {
  z-index: 10000;
}
</style>