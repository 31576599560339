import {createRouter, createWebHistory} from "vue-router";
import app from "@/store/app";
import plugins from "@/store/plugins";

const routes = [
  {
    path: "/application",
    name: "application",
    children: [],
    component: () => import("@/application.vue"),
    beforeEnter: (to, from, next) => {
      if (app.auth.isAuth) return next();
      else
        return next({
          path: "/signin",
          query: {
            to: to.fullPath,
          },
        });
    },
  },
  {
    path: "/app",
    name: "app",
    beforeEnter: (to, from, next) => {
      if (app.auth.isAuth) {
        if (checkRouter(to.fullPath) == false) {
            return next("/app/");
        } else return next();
      } else
        return next({
          path: "/signin",
          query: {
            to: to.fullPath,
          },
        });
    },
    component: () => import("@/App.vue"),
    children: [
      {
        path: "",
        name: "app-index",
        component: () => import("@/pages/index.vue"),
      },
      {
        path: "dashboard",
        name: "app-dashboard",
        component: () => import("@/pages/Dashboard.vue"),
      },
      {
        path: "session",
        name: "app-session",
        component: () => import("@/pages/session.vue"),
      },
      {
        path: "users",
        name: "app-users-list",
        component: () => import("@/pages/users/list.vue"),
      },
      {
        path: "users/:id",
        name: "app-users-edited",
        component: () => import("@/pages/users/edited.vue"),
      },
      {
        path: "roles",
        name: "app-roles-list",
        component: () => import("@/pages/roles/list.vue"),
      },
      {
        path: "roles/:id",
        name: "app-roles-edited",
        component: () => import("@/pages/roles/edited.vue"),
      },
      {
        path: "shortcut",
        name: "app-shortcut",
        component: () => import("@/pages/shortcut.vue"),
      },
      {
        path: "fileExplorer",
        name: "app-fileExplorer",
        component: () => import("@/pages/fileExplorer"),
      },
      {
        path: "contact",
        name: "app-contact",
        component: () => import("@/pages/contact.vue"),
      },
      {
        path: "setting",
        name: "setting",
        children: [
          {
            path: "",
            component: () => import("@/pages/setting/general"),
          },
        ],
      },
      {
        path: "profile",
        name: "app-profile",
        component: () => import("@/pages/profile.vue"),
      },
      {
        path: "landing",
        name: "app-landing",
        component: () => import("@/pages/Landing.vue"),
      },
      {
        path: "store",
        name: "app-store",
        component: () => import("@/pages/store/index.vue"),
      },
      {
        path: ":pathMatch(.*)*",
        name: "app-notfoundApp",
        component: () => import("@/pages/NotFound.vue"),
      },
    ],
  },
  {
    path: "/signin",
    name: "signin",
    beforeEnter: (to, from, next) => {
      if (app.auth.isAuth) {
        let query = to.query;
        if (to.query.to) {
          return next(query.to);
        } else return next("/app");
      } else return next();
    },
    component: () => import("@/pages/Login.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notfound",
    component: () => import("@/pages/404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async function (to, from, next) {
  app.loading = true;
  if (!app.auth.init) {
    app.loadSetting();
    await app.validToken();
    plugins.init();
    app.auth.init = true;
  }
  next();
});

router.afterEach(() => {
  app.loading = false;
});
export default router;

export function checkRouter(path) {
  if (app.user.roleId == "admin") return true;
  path = path.replace("/app/", "");
  if(path == '' || path =='/app')return true;
  for (const key in app.role.route) {
    let element = app.role.route[key];
    for (let i = 0; i < element.length; i++) {
      if (path.startsWith(element[i].replace("/app/", ""))) return true;
    }
  }
  return false;
}
