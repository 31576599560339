export default {
    "users": {
        "text": "Users",
        "key": "menu.users",
        "roles": {
            "delete": {
                "text": "Delete",
                "key": "button.delete",
                "value": false
            },
            "create": {
                "text": "Create",
                "key": "button.create",
                "value": false
            },
            "edited": {
                "text": "Edited",
                "key": "button.edited",
                "value": false
            },
            "view": {
                "text": "View",
                "key": "button.view",
                "value": false
            }
        }
    },
    "roles": {
        "text": "Roles",
        "key": "menu.roles",
        "roles": {
            "delete": {
                "text": "Delete",
                "key": "button.delete",
                "value": false
            },
            "create": {
                "text": "Create",
                "key": "button.create",
                "value": false
            },
            "edited": {
                "text": "Edited",
                "key": "button.edited",
                "value": false
            },
            "view": {
                "text": "View",
                "key": "button.view",
                "value": false
            }
        }
    },
    "shortcut": {
        "text": "Shortcut",
        "key": "menu.shortcut",
        "roles": {
            "delete": {
                "text": "Delete",
                "key": "button.delete",
                "value": false
            }
        }
    },
    "session": {
        "text": "Session",
        "key": "menu.session",
        "roles": {
            "logout": {
                "text": "logout",
                "key": "menu.logout",
                "value": false
            }
        }
    },
    "fileExplorer": {
        "text": "File Explorer",
        "key": "menu.fileExplorer",
        "roles": {
            "upload": {
                "text": "Upload",
                "key": "button.upload",
                "value": false
            },
            "create": {
                "text": "Create",
                "key": "button.create",
                "value": false
            },
            "rename": {
                "text": "Rename",
                "key": "fileExplorer.rename",
                "value": false
            },
            "delete": {
                "text": "Delete",
                "key": "button.delete",
                "value": false
            },
            "shortcut": {
                "text": "shortcut",
                "key": "fileExplorer.shortcut",
                "value": false
            }
        }
    }
}