export default {
    menu: {
        dashboard: "لوحة التحكم",
        store: "المتجر",
        profile: "الملف الشخصى",
        setting: "الاعدادات",
        general: "عام",
        news: "الاخبار",
        app: "التطبيق",
        tasks: "المهام",
        contact: "تواصل معنا",
        info: "عنا",
        website: "الموقع الالكترونى",
        home: "الرئيسية",
        system: "النظام",
        about: "عنا",
        "logout": "تسجيل خروج",
        "fileExplorer": "متصفح الملفات",
        "shortcut": "الاختصارات",
        "persons": "الاشخاص",
        "users": "المستخدمين",
        "roles": "الادوار",
        "list": "القائمة",
        "add": "اضافة",
        "session": "الاجهزة المفتوحة"
    },
    signIn: {
        welcome: "مرحبا بك يا",
        signIn: "سجل الدخول للاستمرار",
        button: "تسجيل الدخول"
    },
    text: {
        "addNewUser": "اضافة مستخدم جديد",
        "editedUser": "تعديل مستخدم",
        "addNewRole": "اضافة دور جديد",
        "EditedRole": "تعديل دور",
        "roleInfo": "بيانات الدور",
        edited: "تعديل",
        done: "تم الحفظ",
        sureLogout: "هل تريد تسجيل خروج ",
        error: "حدث خظا",
        areSure: "هل انت متاكد من حذف العنصر ",
        confirmation: "تأكيد!",
        yes: "نعم",
        no: "لا"
    },
    form: {
        email: "الايميل",
        name: "الاسم",
        username: "اسم المستخدم",
        password: "الرقم السري",
        state: "الحالة",
        "id": "الرقم التعريفى",
        "role": "الدور",
        image: "الصورة",
        description: "الوصف",
        address: "العنوان",
        type: "النوع",
        version: "الاصدار",
        phone: "رقم الجوال",
        path: "الرابط",
        message: "الرسالة"
    },
    button: {
        add: "اضافة",
        delete: "حذف",
        save: "حفظ",
        close: "اغلاق",
        open: "فتح",
        refresh: "تراجع",
        "upload": "تحميل",
        check: "تحقيق",
        validateToken: "التحقق من صحة الرخصة",
        license: "رخصة البرنامج",
        differentLicense: "رخضة جديدة",
        more: "تصفح",
        "create": "انشاء",
        "edited": "تعديل",
        "view": "رؤية",
        update: "تحديث",
        send: "ارسال"
    },
    setting: {
        scale: "الحجم",
        menu: "القائمة",
        language: "اللغة",
        theme: "الثيم"
    },
    store: {
        features: "المميزات",
        version: "الاصدرات السابقة",
        faq: "الاسئلة الشائعة"
    },
    404: {
        lost: "يبدو أنك فقدت",
        available: "المورد المطلوب غير متوفر."
    },
    "fileExplorer": {
        "folders": "المجلدات",
        "files": "الملفات",
        "rename": "اعادة تسمية",
        "download": "تحميل",
        "shortcut": "اختصار"
    }
}