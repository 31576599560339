import {defineAsyncComponent, markRaw, reactive} from "vue";
import router from "@/router";
import {loadModule} from "@/service/loadModule";
import ar from "@/i18n/ar";
import en from "@/i18n/en";
import geteRelativeUrl from "@/getUrl";
import app from "./app";
import axios from "axios";
import navigation from "@/router/navigation";
import application from "@/router/application";
import roles from "@/router/roles";

export default reactive({
  widget: [],
  toolbar: [],
  tasks: [],
  async init() {
    try {
      this.initVariable();
      await this.loadScript();
    } catch (err) {
      if (err.response && err.response.status == 403) {
        router.push("/app/setting/serial");
      }
    }
  },
  async loadScript() {
    const {data} = await axios.get(`plugins/script`);
    data.forEach(element => {
      try {
        let ele = document.createElement("script");
        ele.async = true;
        ele.src = `${geteRelativeUrl()}plugins/file?path=${element.path.join()}`;
        document.body.append(ele);
        ele.onload = async () => {
          await plugins[element.path[0]].call(this, element.info);
        };
        ele.onerror = () => {};
      } catch (err) {
        console.log(err);
      }
    });
  },
  initVariable() {
    this.toolbar = [];
    this.widget = [];
    this.tasks = [];
  },
  addWidget(item) {
    this.widget.push(
      markRaw(defineAsyncComponent(() => loadModule(item.file, item.name)))
    );
  },
  addTask(item) {
    this.tasks.push(
      markRaw(defineAsyncComponent(() => loadModule(item.file, item.name)))
    );
  },
  addToExplorer(data) {
    application[data.id] = data;
  },
  addLanguage(code, key, value) {
    switch (code) {
      case "ar":
        if (ar[key] != undefined) return;
        ar[key] = value;
        break;
      case "en":
        if (en[key] != undefined) return;
        en[key] = value;
        break;
    }
  },
  addToToolbar(route) {
    this.toolbar.push(route);
  },
  addToSetting(route) {
    navigation["System"].items["/app/setting"].items.push(route);
  },
  addToDashboard(cat, item) {
    navigation[cat].items[item.to] = item;
    app.adminRoute[cat].push(item.to);
  },
  addToDashboardRoot(cat, item, routes = []) {
    navigation[cat] = item;
    app.adminRoute[cat] = routes;
  },
  addRole(role) {
    roles[role.id] = role;
  },
  addPage(page, to = "app") {
    router.addRoute(to, {
      name: page.name,
      path: page.path,
      component: defineAsyncComponent(() => loadModule(page.file, page.name)),
    });
    setTimeout(() => {
      let current = router.currentRoute.value;
      router.push(current.fullPath);
    }, 50);
    // let current = router.currentRoute.value
    // let path = page.path.slice(0, page.path.indexOf(":"))
    // if (current.path.startsWith(path))
    // {
    //     router.push(current.fullPath)
    // }
  },
});
